.flicking {
    display: flex;
    flex-direction: row;
    overflow: auto;
    width: 100%;
    height: 300px;
}

.panel {
    width: 200px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.panel img {
    max-width: 100%;
    height: auto;
}

.description {
    font-size: 20px;
    text-align: center;
}