


nav {
    position: fixed;
    top: 0;
    width: 100%;
    background-color: tan;
    padding: 10px 0;
    display: flex;
    justify-content: space-between;
}

.nav-title {
    margin-left: 20px;
    font-weight: bold;
    font-size: 1.5rem;
    
}

.nav-links {
    color: black;
    display: flex;
    justify-content: space-around;
    width: 30%;
    font-size: 1.5rem;
    
}

nav a {
    color: black;
    text-decoration: none;
    
}

nav a:hover {
    font-weight: bold;
}

@media (max-width: 768px) {
nav {
    width: 100%;
    background-color: tan;
}
nav .nav-title {
    margin-left: 10px;
}
nav .nav-links {
    padding-right: 10px;

    width: 50%;
    
    
}
}
