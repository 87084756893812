.login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f0f0f0;
}


.back-button {
    margin-bottom: 20px;
    padding: 10px 20px;
    background-color: #007BFF;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.login-form {
    display: flex;
    flex-direction: column;
    width: 300px;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: #fff;
}

.login-form input {
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.login-form input[type="submit"] {
    cursor: pointer;
    background-color: #007BFF;
    color: #fff;
    border: none;
}

.modal-content {
    background-color: #f9f9f9;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
}

/* Style the modal header */
.modal-header {
    padding: 2px 16px;
    background-color: #007BFF;
    color: white;
}

/* Style the modal body */
.modal-body {
    padding: 2px 16px;
}

/* Style the modal footer */
.modal-footer {
    padding: 2px 16px;
    background-color: #007BFF;
    color: white;
}

/* Style the input field in the modal */
.modal-body input[type="email"] {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    box-sizing: border-box;
    border: 2px solid #ccc;
    border-radius: 4px;
}

.modal-dialog {
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}