/* LandingPage.css */




.grid-container {
    display: grid;
    flex-direction: column;
    height: 100vh;
    width: 100%;
    
}

.row {
    display: flex;
    align-items: center;
    padding: 20px;
    flex-wrap: nowrap;
  }

.row:nth-child(even) {
    flex-direction: row-reverse;
}

.row img {
    max-width: 40%;
    max-height: 80vh; 
    object-fit: cover; 
    align-self: center; 
}



/*About Me*/

#about-me {
    background-color: beige;
    margin-top: 40px;
}

#about-me .about-me-content {
    width: 50%;
}

/* MY BOOKS */
.book-rec-content {
    width: 100%; /* or any value that suits your layout */
    /* other styles */
  }

/* MY LINKS */



#my-links {
    background-color: tan;
    padding: 20px;
    border-radius: 10px;
    
}

#my-links .affiliate-links{
    width: 50%;
}

#my-links h2 {
    margin-bottom: 20px;
}

#my-links form {
    display: flex;
    margin-bottom: 20px;
}

#my-links form input[type="text"] {
    flex: 1;
    margin-right: 10px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
}

#my-links form button {
    padding: 10px 20px;
    border: none;
    background-color: #007BFF;
    color: white;
    cursor: pointer;
    border-radius: 5px;
}

#my-links ul {
    list-style-type: none;
    padding: 0;
}

#my-links ul li {
    background-color: #fff;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

#my-links ul li div {
    display: flex;
    align-items: center;
}

#my-links ul li div img {
    padding-right: 10px;
}

#my-links ul li a {
    color: #007BFF;
    text-decoration: none;
}

#my-links ul li .delete-button {
    border: none;
    background-color: #dc3545;
    color: white;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 5px;
}

#my-links ul li .edit-button {
    border: none;
    background-color: #04e20c;
    color: white;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 5px;
    margin-right: 10px;
}
/* MY SOCIALS */

#my-socials {
    background-color: beige;
}

#my-socials h2 {
    margin-bottom: 20px;
}
#my-socials .social-content {
    width: 50%;
    
align-items: center;
}

#my-socials .IGsocials{
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
}

#my-socials .YTsocials {
    
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
    
}




/* Media query for mobile responsiveness */
@media (max-width: 768px) {
    


    .grid-container {
        display: flex;
        flex-direction: column;
        height: 100vh;
        width: 100%;
        
    }
    
    .row {
        display: flex;
        align-items: center;
        justify-content: space-around;
        padding: 20px;
        flex-wrap: wrap;
        
    }
    
    .row:nth-child(even) {
        flex-direction: row-reverse;
    }
    
    .row img {
        max-width: 100%; /* adjust this value as needed */
        max-height: 80vh; 
        object-fit: cover; 
        align-self: center; 
      }

    /* MY SOCIALS */

    #my-socials h2 {
        margin-top: 10px;
        text-align: center;
    }
    #my-socials .social-content {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    #my-socials .IGsocials {
        aspect-ratio: 1/1;
        width: 100%;
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
    }

    #my-socials .YTsocials {
        aspect-ratio: 16/9;
        width: 100%;
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
    }

    
    

    
    
}